<template>
  <div>
    <div class="newsListDetail_head">
      <div class="newsListDetail_head_title" v-show="name1" @click="toPath('/', {})">{{ name1 }} &nbsp;</div>
      <div class="newsListDetail_head_title" v-show="name2" @click="toPath('/base', { companyId: name2Id, index: 0 })">
        &nbsp;>&nbsp; {{ name2 }}</div>
      <div class="newsListDetail_head_title" v-show="name3"
        @click="toPath('/baseCompanyDetail', { companyId: name3Id, index: 0, pCompanyName: name2, pCompanyId: name2Id })">
        &nbsp;>&nbsp; {{ name3 }}</div>
      <div class="newsListDetail_head_title">&nbsp;>&nbsp; <span class="active">{{ info.newsTitle }}</span></div>
    </div>
    <div class="newsListDetail_list">
      <div class="newsListDetail_list_title">
        {{ info.newsTitle }}
      </div>
      <div class="newsListDetail_list_time">
        发布时间：{{ info.year }}-{{ info.day }}
      </div>
      <div class="newsListDetail_list_value" v-html="info.newsContent"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { getAccessToken, getSignature } from '@/api/index'
export default {
  name: "newsListDetail",
  data() {
    return {
      newsList_bg: require("../../static/image/newsList_bg.png"),
      info: {},
      name1: '',
      name2: '',
      name2Id: '',
      name3: '',
      name3Id: ''

    };
  },
  mounted() {
    window.scroll(0, 0);
    const id = this.$route.query.id;
    this.name1 = this.$route.query.name1;
    this.name2 = this.$route.query.name2;
    this.name2Id = this.$route.query.id2;
    this.name3 = this.$route.query.name3;
    this.name3Id = this.$route.query.id3;
    this.getInfo(id);
  },
  methods: {
    getWXInfo() {
      const that = this
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: that.info.newsTitle, // 分享标题
            desc: that.info.newsIntroduction ? that.info.newsIntroduction : '浙江中峪农业发展有限责任公司（简称“中峪农业”），秉持以绿水青山就是金山银山的发展理念，传承发展中医药产业为己任的宗旨，以数字化改革和乡村振兴为初心，致力于在农业领域推动创新数字化发展，专注于道地药材从源头孵化与保护产业生态，带动地方就业、加工、仓储物流等，推动地方文旅、科研、产教研学、人才培养等多元化产业发展；同时积极探索药、风、光、零碳、能源产业带的同步推进。在集团内循环产业链赋能下，跨界融合部署，遵循国家政策，紧跟国家战略，以“数字经济新农业 乡村振兴新载体”的创新产业布局，致力于实现乡村产业振兴和多维推动共同富裕，打造数字化新农业的全新产业标杆。', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: that.info.newsCover, // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
    getInfo(id) {
      //获取独立的数据
      axios
        .get(`https://admin.zoneyu.cn/cms/api/app/news/${id}`)
        .then((res) => {
          this.info = res.data.data;
          this.getWXInfo()
          const updateTime = new Date(this.info.publishTime);
          this.info.day =
            updateTime.getDate() > 9
              ? updateTime.getDate()
              : "0" + updateTime.getDate();
          this.info.year =
            updateTime.getFullYear() +
            "." +
            (updateTime.getMonth() + 1 > 9
              ? updateTime.getMonth() + 1
              : "0" + (updateTime.getMonth() + 1));
        });
    },
    toPath(path, query) {
      this.$router.push({path, query});
    }
  },
};
</script>
<style scoped lang="scss">
.newsListDetail_bg {
  img {
    width: 100%;
  }
}

.newsListDetail_head {
  width: 1280px;
  margin: 20px auto;
  font-size: 18px;
  font-weight: 400;
  color: #858585;
  line-height: 20px;
  overflow: hidden;

  .newsListDetail_head_title {
    margin-top: 40px;
    display: inline-block;
    cursor: pointer;
  }
  .active{
    color: #000;
  }
}

.newsListDetail_list {
  margin: 40px auto;
  width: 1280px;
  text-align: center;

  .newsListDetail_list_title {
    color: #333;
    font-size: 25px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
  }

  .newsListDetail_list_time {
    font-size: 12px;
    font-weight: 400;
    color: #abaeb0;
    line-height: 17px;
    margin-top: 20px;
  }

  .newsListDetail_list_value {
    margin-top: 40px;
    text-align: left;
    margin-bottom: 40px;
  }
}</style>
